/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./popEarnTip.vue?vue&type=template&id=ff14b4a2&scoped=true"
import script from "./popEarnTip.vue?vue&type=script&lang=js"
export * from "./popEarnTip.vue?vue&type=script&lang=js"
import style0 from "./popEarnTip.vue?vue&type=style&index=0&id=ff14b4a2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff14b4a2",
  null
  
)

export default component.exports