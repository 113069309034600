import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const airdropVuex = createNamespacedHelpers("airdrop");
import vailcode from "@utils/errcode";
import { nFormatter } from "@utils/index";
export default {
  name: "airdropReward",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"]),
    ...airdropVuex.mapState(["airdropInfo", "airdropInvitation"])
  },
  data() {
    return {
      isFlag: true,
      itemCondition: {},
      itemData: {},
      checkNum: 0,
      timeVal: null,
      popEarnTipShow: false,
      isPopRewardResultShow: false,
      popShareShow: false,
      selectRow: {}
    };
  },
  beforeRouteLeave(to, from, next) {
    this.popEarnTipShow = false;
    this.isPopRewardResultShow = false;
    this.popShareShow = false;
    clearTimeout(this.timeVal);
    this.isFlag = true;
    this.$nextTick(() => {
      next();
    });
  },
  watch: {
    "airdropInfo": {
      handler(newVal, oldVal) {
        if (newVal && newVal.invitation_amount > 0) {
          this.getAirdropInvitationList([newVal.id, 100, 1]);
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    let id = this.$route.query.id || 0;
    if (id > 0) {
      await this.getAirdropInfo([id]);
    }
  },
  methods: {
    ...walletVuex.mapActions(["loadTaskCount"]),
    ...userVuex.mapMutations(['setUserData']),
    ...airdropVuex.mapMutations(["setAirdropInfo"]),
    ...airdropVuex.mapActions(["getAirdropInfo", "getAirdropInvitationList"]),
    onShare() {
      this.WebApp.openTelegramLink(`https://t.me/share/url?text=${encodeURIComponent(this.airdropInfo.invitation_tip)}&url=https://t.me/${this.setting.botname}/app?startapp=ref_${this.userData.ref_code}`);
    },
    async onRecive(airdropId) {
      if (!this.isFlag) return;
      this.isFlag = false;
      let rs = await this.$http.post("/airdrop/receive", {
        airdropId
      });
      vailcode(rs, async () => {
        await this.setAirdropInfo(rs.data);
      });
      this.isFlag = true;
    },
    async jumpTo(item) {
      if (!item.completed) {
        if (item.condition_type == 'task' || item.condition_type == 'airdrop_task') {
          if (item.condition_task.type == 'matrix') {
            this.$router.push({
              path: 'taskDetail',
              query: {
                id: `${item.condition_value}`
              }
            });
            return;
          } else if ((item.condition_task.type || '') == 'adsgram' || (item.condition_task.provider || '') == 'adsgram') {
            this.$loading.show();
            this.selectRow = {
              value: item.condition_task.reward,
              unit: this.setting.coin,
              type: "tapcoins"
            };
            const AdController = await window.Adsgram.init({
              blockId: item.condition_task.url
            });
            await AdController.show().then(result => {
              // user watch ad till the end
              // your code to reward user
              this.$loading.hide();
              if (result.done && !item.completed) this.checkExtralTask(item, item.condition_task, 0);
            }).catch(result => {
              // user skipped video or get error during playing ad
              // do nothing or whatever you want
              console.log(result);
              this.$loading.hide();
            });
            this.$loading.hide();
            return;
          } else if ((item.condition_task.type || '') == 'tmads' || (item.condition_task.provider || '') == 'tmads') {
            this.$loading.show();
            this.selectRow = {
              value: item.reward,
              unit: this.setting.coin,
              type: "tapcoins"
            };
            TMAds.init({
              app_key: item.url,
              init_data: this.WebApp.initData,
              init_data_unsafe: this.WebApp.initDataUnsafe
            });
            TMAds.showInterstitialAd().then(() => {
              //interstitial ad has been successfully shown and closed
              this.$loading.hide();
              if (!item.completed) this.checkExtralTask(item, item.condition_task, 0);
            }).catch(() => {
              //interstitial ad failed to show (likely due to the network issues)
              this.$loading.hide();
            });
            return;
          } else if ((item.condition_task.type || '') == 'page') {
            //if(!item.completed)
            this.$router.push(`/${item.condition_task.target}?id=${item.condition_task.id}`);
            return;
          } else if (item.condition_task.type == 'telegram' || item.condition_task.type == 'channel') {
            this.WebApp.openTelegramLink(item.condition_task.url);
          } else {
            this.WebApp.openLink(item.condition_task.url);
          }

          // this.itemCondition = item;
          // this.itemData = item.condition_task;
          // this.itemData.kind = item.condition_type == 'airdrop_task' ? 'airdrop' : ''
          // this.itemData.adv = 0;
          // this.selectRow =  {value:item.condition_task.reward, unit:this.setting.coin, type :"tapcoins"}
          // this.popEarnTipShow = true

          setTimeout(() => {
            this.checkExtralTask(item, item.condition_task, 0);
          }, 2000);
        } else if (item.condition_type == 'mining_earnings') {
          this.$router.push({
            path: 'mine'
          });
        } else if (item.condition_type == 'invitation_count') {
          this.popShareShow = true;
        }
      }
    },
    async checkExtralTask(condition, item, adv) {
      if (this.checkNum >= 5) {
        this.checkNum = 0;
        return;
      }
      this.checkNum++;
      let rs = await this.$http.post("/task/complete", {
        taskId: item.id,
        adv: adv,
        kind: condition.condition_type == 'airdrop_task' ? 'airdrop' : ''
      });
      if (rs.code == 0) {
        condition.completed = 1;
        item.completed = 1;
        if (adv == 1 && item.cyclical == 1) {
          item.countdown = item.cycle_interval;
        }
        let newUserData = {
          ...this.userData,
          ...(rs.data.userInfo ? rs.data.userInfo : rs.data)
        };
        this.setUserData(newUserData);
        this.onAccept();
        if (adv == 0) this.loadTaskCount();
      } else if (rs.code == 302) {
        setTimeout(() => {
          this.checkExtralTask(condition, item, adv);
        }, 2000);
      }
    },
    onAccept() {
      //this.itemCondition.completed=1;
      this.getAirdropInfo([this.airdropInfo.id]);
      this.isPopRewardResultShow = false;
    }
  }
};